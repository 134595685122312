import type { ParamsType } from "@libfunc/api";
import { ApiError, EntityError, Method, URI, request } from "@libfunc/api";

import { AlertType, addMessage } from "@base/store";

import { metricsService } from "../metrics";
import { saveRedirectUrl } from "../storage";

export const errorHandle = (error: unknown) => {
  if (error instanceof EntityError) {
    const { text, field } = error.error;
    const msg = field ? `${field}: ${text}` : text;

    addMessage(msg, AlertType.Error);

    return;
  }

  if (error instanceof ApiError) {
    const err = error.message ? `api: ${error.message}` : $.errors.api;
    addMessage(err, AlertType.Error);

    return;
  }

  if (error instanceof Error) {
    metricsService.addError(error);
  } else if (typeof error === "string") {
    metricsService.addError({
      message: error,
      name: "unknown error",
      error,
    });
  } else {
    metricsService.addError({
      message: "",
      name: "unknown error",
      error,
    });
  }

  addMessage($.errors.api, AlertType.Error);
};

const requiredSignin = () => {
  saveRedirectUrl(window.location.toString());
  console.info("requiredSignin");
};

const metricSend = (uri: string, ms: number) => {
  metricsService.add({
    event: "api-time",
    uri,
    ms,
  });
};

export const get = <D>(path: string, params?: ParamsType) =>
  request<D>({
    path: URI + path,
    params,
    method: Method.Get,
    requiredSignin,
    metricSend,
  });

export const post = <D>(
  path: string,
  body?: unknown,
  options?: {
    params?: ParamsType;
    timeout?: number;
  },
) =>
  request<D>({
    path: URI + path,
    body,
    method: Method.Post,
    requiredSignin,
    metricSend,
    ...options,
  });

export const put = <D>(path: string, body: unknown, params?: ParamsType) =>
  request<D>({
    path: URI + path,
    params,
    body,
    method: Method.Put,
    requiredSignin,
    metricSend,
  });

export const patch = <D>(path: string, body: unknown, params?: ParamsType) =>
  request<D>({
    path: URI + path,
    params,
    body,
    method: Method.Patch,
    requiredSignin,
    metricSend,
  });

export const del = <D>(path: string, body?: unknown, params?: ParamsType) =>
  request<D>({
    path: URI + path,
    params,
    body,
    method: Method.Delete,
    requiredSignin,
    metricSend,
  });
