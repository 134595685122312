import { isDev } from "@libfunc/env";

import { UserIdentityType } from "@shared/models";
import type { UserEntry } from "@shared/models/user";

import { MAX_PHONE_WIDTH } from "@base/consts";

export const noop = () => void 0;

export function now(): number {
  const time = Date.now();
  now.last = time > now.last ? time : now.last + 1;
  return now.last;
}

now.last = Date.now();

export const parseAppVersion = (version: string) =>
  version.split(".").map((v) => Number.parseInt(v, 10)) as [
    number,
    number,
    number,
  ];

export const isPhone = () =>
  (window.innerWidth || window.screen.width) <= MAX_PHONE_WIDTH;

// firstname lastname
export const getFullName = (user: {
  firstname?: string;
  lastname?: string;
}): string => {
  const { firstname, lastname } = user;
  let name: string;
  if (!lastname) {
    name = firstname ?? "";
  } else if (!firstname) {
    name = lastname;
  } else {
    name = `${firstname} ${lastname}`;
  }
  return name.trim();
};

// firstname lastname or @username or email or id
export const showUser = (user: UserEntry) => {
  return (
    getFullName({
      firstname: user.firstname,
      lastname: user.lastname,
    }) ||
    (user.username && `@${user.username}`) ||
    (user.email && `${$.global.user} ${user.email}`) ||
    `${$.global.user} ${user.id}`
  );
};

// до 10_000 пишем как есть (726, 4334)
// при наведении или тапе на счетчик - отображаем сверху точное количество
// после 10к и до 1м округляем до Nk (10к, 76к, 123к, 879к)
// после 1м округляем до B.Nm (1.2m, 12.5m, 125.7m)
export const shortCount = (count: number): string => {
  if (count < 10_000) {
    return count.toString();
  }
  if (count < 1_000_000) {
    return `${Math.trunc(count / 1000).toString()} + K`;
  }
  return `${(Math.trunc(count / 100_000) / 10).toString()} + M`;
};

// https://stackoverflow.com/questions/2113908/what-regular-expression-will-match-valid-international-phone-numbers
// const phoneRegex = /^\+[1-9][0-9]{3,14}$/;
export const phoneRegex = /^[0-9\-+ ()]{9,15}$/;
export const ruPhoneNumber = /^\+?[780]([\d\-+ ()]{9,15})$/;
export const emailRegex = /^\S+@\S+\.\S+$/;
export const usernameRegex = /^[a-zA-Z][a-zA-Z0-9_]{1,19}[a-zA-Z0-9]$/;
export const orgnameRegex = /^[a-zA-Z][a-zA-Z0-9-]{1,19}[a-zA-Z0-9]$/;

export const getUserLoginType = (user: string): UserIdentityType | null => {
  if (emailRegex.test(user)) {
    return UserIdentityType.email;
  }
  if (ruPhoneNumber.test(user)) {
    return UserIdentityType.phone;
  }
  if (phoneRegex.test(user)) {
    return UserIdentityType.phone;
  }
  if (usernameRegex.test(user)) {
    return UserIdentityType.username;
  }
  return null;
};

// https://docs.w3cub.com/dom/subtlecrypto/digest
export const hashPassword = async (
  password: string,
): Promise<{ isEncrypt: boolean; password: string }> => {
  if (crypto.subtle) {
    const msgBuffer = new TextEncoder().encode(password);
    const digest = await crypto.subtle.digest("SHA-256", msgBuffer);
    const hashArray = new Uint8Array(digest);
    const hashHex = [...hashArray]
      .map((b: number) => b.toString(16).padStart(2, "0"))
      .join("");
    return {
      isEncrypt: true,
      password: hashHex,
    };
  }
  return {
    isEncrypt: false,
    password,
  };
};

export const changeTitle = (title: string) => {
  const appTitle = isDev ? "LOCAL Form.Love" : "Form.Love";
  document.title = title ? `${title} | ${appTitle}` : appTitle;
};

export const normalizePhone = (text: string) => {
  const phone = text.trim().replace(/[\D]/g, "");
  if (text.startsWith("+")) {
    return `+${phone}`;
  }
  return phone;
};

export const normalizeName = (name: string) => {
  return name.trim();
};

export const normalizeUsername = (name: string) => {
  return name.trim().toLowerCase();
};
