import { errorHandle } from "@base/api";
import type { SentryUserId } from "@base/models";
import { appId } from "@base/storage";
import { appStoreMethods } from "@base/store";

import { checkAuth } from "@api/auth";

import { initUser } from "../root-data";

export const init = async (): Promise<SentryUserId> => {
  if (!initUser) {
    try {
      const body = await checkAuth();
      const { user, isAuth } = body;
      if (!isAuth) {
        appStoreMethods.setUser(null);

        return { appId };
      }

      if (!user) {
        console.error("is auth, but don't user!");
        return { appId };
      }

      appStoreMethods.setUser(user);
      return { userId: user.id };
    } catch (error) {
      errorHandle(error);
      return { appId };
    }
  } else {
    appStoreMethods.setUser(initUser);
    return { userId: initUser.id };
  }
};
