import type { RouteDefinition } from "@solidjs/router";
import type { Component } from "solid-js";
import { lazy } from "solid-js";

import { preloadFormData } from "@comp/pages/forms/fill/FormResources";

const Signup = lazy(() => import("@comp/pages/auth/Signup"));
const Login = lazy(() => import("@comp/pages/auth/Login"));
const GoogleRedirect = lazy(() => import("@comp/pages/auth/GoogleRedirect"));
const PasswordReset = lazy(() => import("@comp/pages/auth/PasswordReset"));
const Profile = lazy(() => import("@comp/profile/Profile"));
const Settings = lazy(() => import("@comp/profile/settings/Settings"));
const User = lazy(() => import("@comp/pages/users/User"));
const Home = lazy(() => import("@comp/pages/home/Home"));
const About = lazy(() => import("@comp/pages/others/About"));
const Docs = lazy(() => import("@comp/pages/others/Docs"));
const Policy = lazy(() => import("@comp/pages/others/Policy"));
const Terms = lazy(() => import("@comp/pages/others/Terms"));
const DeviceInfo = lazy(() => import("@comp/pages/others/DeviceInfo"));
const FormsPage = lazy(() => import("@comp/pages/forms/FormsPage"));
const FormEdit = lazy(() => import("@comp/pages/forms/edit/FormEdit"));
const NewForm = lazy(() => import("@comp/pages/forms/edit/NewForm"));
const Form = lazy(() => import("@comp/pages/forms/fill/Form"));
const NotFound = lazy(() => import("@comp/pages/errors/404"));

const r = (path: string, component: Component): RouteDefinition => ({
  path,
  component,
});

export const routes: RouteDefinition[] = [
  r("/", Home),
  r("/reset-password", PasswordReset),
  r("/signup", Signup),
  r("/login", Login),
  r("/login-with-google", GoogleRedirect),
  r("/u/:id", User),
  {
    path: "/f",
    children: [
      r("/", FormsPage),
      r("/new", NewForm),
      {
        path: "/:id",
        component: Form,
        preload: preloadFormData,
      },
      r("/:id/edit", FormEdit),
    ],
  },
  // r("/invite/:id", Invite),
  // r("/search", Search),
  r("/profile", Profile),
  r("/settings", Settings),
  r("/about", About),
  r("/docs", Docs),
  r("/privacy-policy", Policy),
  r("/terms-of-service", Terms),
  r("/device-info", DeviceInfo),
  r("/404", NotFound),
];
